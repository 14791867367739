import { COLORS, FONTS } from "@constants";
import { getInitials } from "@utils/textUtils";
import { useState } from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";

export interface ProfilePictureProps {
  user?: {
    image: {
      medium: {
        url: string | null;
      };
    };
    name: string;
  };
  size: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
  margin?: string | 0;
  forMulti?: boolean;
  isUserHidden?: boolean;
  color?: string;
  bgColor?: string;
}

const ProfilePicture = ({
  user,
  size,
  margin = 0,
  forMulti = false,
  isUserHidden = false,
  color = COLORS.BLACK,
  bgColor,
}: ProfilePictureProps) => {
  const [didImageError, setDidImageError] = useState(false);
  const defaultBgColor = size === "lg" ? COLORS.NEUTRAL_100 : COLORS.NEUTRAL_200;

  return (
    <Container
      size={size}
      margin={margin}
      forMulti={forMulti}
      color={color}
      bgColor={bgColor ? bgColor : defaultBgColor}
    >
      {(() => {
        if (isUserHidden || !user)
          return <Icon name="Profile" size={size === "xxs" ? "xs" : size} />;
        if (user.image?.medium?.url && !didImageError)
          return (
            <Picture
              src={user.image.medium.url}
              alt="profile picture"
              onError={() => {
                setDidImageError(true);
              }}
            />
          );
        return getInitials(user.name);
      })()}
    </Container>
  );
};

export default ProfilePicture;

export const PROFILE_PICTURE_SIZE_STYLES = css`
  ${({ size }) => {
    switch (size) {
      case "xxs": {
        return css`
          font-size: 10px;
          height: 20px;
          width: 20px;
          min-width: 20px;
        `;
      }
      case "xs": {
        return css`
          font-size: 12px;
          height: 28px;
          width: 28px;
          min-width: 28px;
        `;
      }
      case "sm": {
        return css`
          font-size: 16px;
          height: 36px;
          width: 36px;
          min-width: 36px;
        `;
      }
      case "md": {
        return css`
          font-size: 23px;
          height: 50px;
          width: 50px;
          min-width: 50px;
        `;
      }
      case "lg": {
        return css`
          font-size: 23px;
          font-weight: 600;
          height: 68px;
          width: 68px;
          min-width: 68px;
        `;
      }
      case "xl": {
        return css`
          font-size: 32px;
          font-weight: 600;
          height: 96px;
          width: 96px;
          min-width: 96px;
        `;
      }
    }
  }};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border: ${({ forMulti }) => (forMulti ? `1px solid ${COLORS.WHITE}` : "none")};
  margin: ${({ margin }) => margin};
  font-weight: 500;
  ${PROFILE_PICTURE_SIZE_STYLES};
  line-height: 0;
`;

const Picture = styled.img`
  height: 100%;
  width: 100%;
  ${FONTS.REGULAR_4};
  border-radius: 50%;
`;
