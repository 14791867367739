/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
export enum SegmentEventName {
  Abandon = "Abandon",
  Accept = "Accept",
  AddToNetwork = "AddToNetwork",
  Advance = "Advance",
  Bounce = "Bounce",
  ClaimProfile = "ClaimProfile",
  Click = "Click",
  CompleteOnboarding = "CompleteOnboarding",
  Create = "Create",
  Decline = "Decline",
  Downvote = "Downvote",
  Follow = "Follow",
  Invite = "Invite",
  Login = "Login",
  Logout = "Logout",
  SendMessage = "SendMessage",
  ReceiveMessage = "ReceiveMessage",
  Open = "Open",
  ReceiveAnswer = "ReceiveAnswer",
  Refer = "Refer",
  Reply = "Reply",
  RequestToJoinNetwork = "RequestToJoinNetwork",
  Resubscribe = "Resubscribe",
  Review = "Review",
  Search = "Search",
  Send = "Send",
  Share = "Share",
  SpamComplaint = "SpamComplaint",
  Submit = "Submit",
  Unfollow = "Unfollow",
  Unsubscribe = "Unsubscribe",
  Update = "Update",
  UpdateKeywords = "UpdateKeywords",
  UpdateInterest = "UpdateInterest",
  Upvote = "Upvote",
  View = "View",
}
