import { STYLES } from "@constants";
import styled from "styled-components";

type Props = {
  content: string;
};

const OneLineSpan = ({ content }: Props) => {
  return <Span title={content}>{content}</Span>;
};

export default OneLineSpan;

const Span = styled.span`
  ${STYLES.ONE_LINE_ALT}
`;
