/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
export enum SegmentEventObject {
  Company = "Company",
  InvitedUser = "InvitedUser",
  Message = "Message",
  Notification = "Notification",
  NotificationSettings = "NotificationSettings",
  Proposal = "Proposal",
  ProposalDiscussion = "ProposalDiscussion",
  Request = "Request",
  RequestComment = "RequestComment",
  RequestForStartupProposal = "RequestForStartupProposal",
  RequestForExpertProposal = "RequestForExpertProposal",
  User = "User",
}
