import {
  request_path,
  requests_path,
  review_system_proposals_request_for_expert_proposals_path,
  review_system_proposals_request_for_startup_proposals_path,
} from "@routes/routes";
import { ProposalReviewStatus } from "@tsTypes/__generated__/enums/proposal_review_status";
import {
  AddReviewersResponse,
  CommercialApplicationPageRequestsResponse,
  MarketplaceRequestsResponse,
  RequestsShowResponse,
  ResearchAreaPageResponse,
  ReviewerDashboardRequestsResponse,
  SponsorDropdownRequest,
  SponsorDropdownRequestsResponse,
} from "@tsTypes/index";
import { ReviewSystemProposalsResponse } from "@tsTypes/proposals/review_system_proposals";
import { RequestPartnerTypes, RequestableType } from "@tsTypes/requests";
import { default as api } from "./request";

export const getRequest = async (slug: string): Promise<RequestsShowResponse> => {
  const response = await api.get(request_path(slug));
  return response.data;
};

export const getRequestsForReviewerDashboard =
  async (): Promise<ReviewerDashboardRequestsResponse> => {
    const response = await api.get(requests_path(), {
      params: {
        for_reviewer_dashboard: true,
      },
    });
    return response.data;
  };

export const toggleRequestEnabled = async (
  requestId: number,
  shouldEnable: boolean
): Promise<number> => {
  const response = await api.put(request_path(requestId), {
    enabled: shouldEnable,
  });
  return response.data.request_id;
};

export const getMarketplaceRequests = async (
  {
    searchFilter,
    requestTypesFilter,
    partnerTypesFilter,
  }: {
    searchFilter: string[];
    requestTypesFilter: RequestableType[];
    partnerTypesFilter: RequestPartnerTypes[];
  } = {
    searchFilter: [],
    requestTypesFilter: [],
    partnerTypesFilter: [],
  }
): Promise<MarketplaceRequestsResponse> => {
  const response = await api.get(requests_path(), {
    params: {
      for_marketplace: true,
      options: {
        search_filter: searchFilter,
        request_type_filter: requestTypesFilter,
        partner_type_filter: partnerTypesFilter,
      },
    },
  });
  if (!response) return { requests: [], scores: [] };
  return response.data;
};

export const getCommercialApplicationPageRequests = async (
  commercialApplicationId: number,
  pageNum: number
): Promise<CommercialApplicationPageRequestsResponse> => {
  const response = await api.get(requests_path(), {
    params: {
      commercial_application_id: commercialApplicationId,
      page_num: pageNum,
    },
  });

  return response.data;
};

// Delete after FlipperGates::Gate::COMMERCIAL_APPLICATIONS is enabled
export const getResearchAreaPageRequests = async (
  rfpProgramIdentifier: string
): Promise<ResearchAreaPageResponse> => {
  const response = await api.get(requests_path(), {
    params: {
      rfp_program_identifier: rfpProgramIdentifier,
    },
  });

  return response.data;
};

export const getRequestsForSponsorDropdowns = async (
  filterByReviewTeamAccess = false
): Promise<SponsorDropdownRequestsResponse> => {
  const response = await api.get(requests_path(), {
    params: {
      for_sponsor_dropdowns: true,
      filter_by_review_team_access: filterByReviewTeamAccess,
    },
  });
  return response.data;
};

export const getExpertRequests = async (): Promise<SponsorDropdownRequestsResponse> => {
  const response = await api.get(requests_path(), {
    params: {
      for_expert: true,
    },
  });
  return response.data;
};

export const addReviewers = async ({
  requestId,
  emails,
  role,
  message,
}: {
  requestId: number;
  emails: string[];
  role: string;
  message?: string;
}): Promise<AddReviewersResponse> => {
  const response = await api.post(`/requests/add_reviewers`, {
    request_id: requestId,
    emails,
    role,
    message,
  });

  return response.data;
};

export const getRequestProposalsForReviewSystem = async (
  request: SponsorDropdownRequest | null,
  status: number | ProposalReviewStatus,
  options: {
    proposalId: number | null;
    searchFilter: string;
    countryFilter: string[];
    organizationTypeFilter: string[];
    organizationNameFilter: string[];
    keywordsFilter: string[];
    requestSolutionsFilter: string[];
    trlFilter: number[];
    evaluationStatusFilter: string[];
    bookmarkedOnlyFilter: boolean;
  }
): Promise<ReviewSystemProposalsResponse> => {
  let requestPath = "";
  switch (request?.requestable_type) {
    case RequestableType.RFP:
    case RequestableType.OPEN_CALL: {
      requestPath = "/proposals";
      break;
    }
    case RequestableType.REQUEST_FOR_STARTUP: {
      requestPath = review_system_proposals_request_for_startup_proposals_path();
      break;
    }
    case RequestableType.REQUEST_FOR_EXPERT: {
      requestPath = review_system_proposals_request_for_expert_proposals_path();
      break;
    }
    default: {
      throw new Error("Invalid request");
    }
  }

  const response = await api.get(requestPath, {
    params: {
      is_for_review_system: true,
      request_id: request.id,
      status,
      proposal_id: options.proposalId,
      search_filter: options.searchFilter,
      country_filter: options.countryFilter,
      organization_type_filter: options.organizationTypeFilter,
      organization_name_filter: options.organizationNameFilter,
      keywords_filter: options.keywordsFilter,
      request_solutions_filter: options.requestSolutionsFilter,
      trl_filter: options.trlFilter,
      evaluation_status_filter: options.evaluationStatusFilter,
      bookmarked_only_filter: options.bookmarkedOnlyFilter,
    },
  });

  return response.data;
};

export const setRequestFeedbackDeadline = async (
  requestId: number,
  deadline: Date
): Promise<string> => {
  const response = await api.put(request_path(requestId), {
    feedback_deadline: deadline,
  });
  return response.data.feedback_deadline;
};

export const getCanSubmitProposal = async (requestId: number): Promise<boolean> => {
  const response = await api.post(`/requests/${requestId}/can_submit_proposal`);
  return response.data.can_submit;
};
