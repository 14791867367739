/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
const COLORS = {
  BLACK: "#232D38",
  RED: "#DE3957",
  GREEN: "#1A9A1F",
  WHITE: "#FFFFFF",
  NEUTRAL_900: "#111827",
  NEUTRAL_850: "#1E252E",
  NEUTRAL_700: "#374151",
  NEUTRAL_600: "#4B5563",
  NEUTRAL_500: "#6B7280",
  NEUTRAL_400: "#9CA3AF",
  NEUTRAL_350: "#C5CAD2",
  NEUTRAL_300: "#D1D7DB",
  NEUTRAL_250: "#DCE2E8",
  NEUTRAL_200: "#E4EBF1",
  NEUTRAL_100: "#EFF3F7",
  NEUTRAL_50: "#F8F9FC",
  BLUE_450: "#2BB3F6",
  BLUE_600: "#0088D6",
  BLUE_650: "#0C76C4",
  BLUE_900: "#004980",
  BLUE_LIGHT_100: "#F5FBFF",
  BLUE_LIGHT_250: "#F2F9FF",
  BLUE_LIGHT_300: "#EEF6FD",
  BLUE_LIGHT_350: "#DEF0FF",
  BLUE_LIGHT_400: "#D9EEFF",
  BLUE_LIGHT_50: "#F9FDFF",
  BLUE_LIGHT_500: "#C6E5FF",
  BLUE_LIGHT_600: "#93CDFF",
  BLUE_LIGHT_650: "#7DC2FD",
  BLUE_LIGHT_700: "#6BB1EC",
  HALO_BLUE: "#009CEA",
  RED_700: "#AC2B42",
  RED_600: "#C9334E",
  RED_300: "#FDBFC9",
  RED_200: "#FFCED6",
  RED_100: "#FFE6EB",
  RED_50: "#FFF6F8",
  ACCENT_800: "#9A1A67",
  ACCENT_600: "#C92D80",
  ACCENT_100: "#F9E1FA",
  ORANGE_900: "#AF5000",
  ORANGE: "#D47200",
  ORANGE_700: "#E7820E",
  ORANGE_500: "#FAB228",
  ORANGE_300: "#FFD88B",
  ORANGE_200: "#FFEAB1",
  ORANGE_100: "#FFF3D3",
  ORANGE_50: "#FFF9E9",
  GREEN_800: "#007C01",
  GREEN_750: "#108815",
  GREEN_400: "#3FD445",
  GREEN_200: "#CEF6D0",
  GREEN_100: "#DDF4DE",
  GREEN_50: "#EFFEF1",
  PINK_800: "#9A1A67",
  PINK_700: "#B22474",
  PINK_600: "#C92D80",
  PINK_200: "#F6CFEC",
  PINK_100: "#F9E1FA",
  PINK_50: "#FDF5FD",
  PURPLE: "#723af5",
  PURPLE_LIGHT: "#EAE8F8",
  RB_PINK: "#EA3592",
  RB_PINK_LIGHT: "#da96b1",
  RB_PINK_BORDER: "#da1c7e",
  RB_PINK_DARK: "#c71470",
};

export default COLORS;
