import { COLORS, FONTS } from "@constants";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  to: string | { pathname: string };
  children: any;
  color?: string;
  font?: string;
  underline?: boolean;
  newTab?: boolean;
  onClick?: () => void;
  margin?: string | 0;
}

const TextLink = ({
  to,
  children,
  color = COLORS.BLACK,
  font = FONTS.MEDIUM_2,
  underline = false,
  newTab = false,
  onClick,
  margin,
}: Props) => {
  return (
    <StyledLink
      to={to}
      color={color}
      font={font}
      $underline={underline}
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noreferrer" : ""}
      onClick={onClick}
      margin={margin}
    >
      {children}
    </StyledLink>
  );
};

export default TextLink;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  ${({ margin }) => margin && `margin: ${margin}`};
  color: ${({ color }) => color};
  ${({ font }) => font};
  ${({ $underline }) => $underline && `text-decoration: underline;`};
  &:hover,
  &:focus {
    color: ${({ color }) => color};
  }
`;
