import { ChipCheckbox, OneLineSpan, ProfilePicture } from "@components/library";
import { COLORS } from "@constants";
import { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  user: {
    image: {
      medium: {
        url: string | null;
      };
    };
    name: string;
  };
  checked: boolean;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  borderColor?: string;
  dataTestId?: string;
}

const UserChipCheckbox = (props: Props) => {
  const { user, ...restProps } = props;

  return (
    <AltChipCheckbox
      {...restProps}
      label={
        <>
          <ProfilePictureContainer>
            <ProfilePicture
              size="xs"
              user={user}
              color={COLORS.BLUE_650}
              bgColor={COLORS.BLUE_LIGHT_500}
            />
          </ProfilePictureContainer>
          <OneLineSpan content={user.name} />
        </>
      }
    />
  );
};

export default UserChipCheckbox;

const AltChipCheckbox = styled(ChipCheckbox)`
  height: 36px;
  &&& {
    ${({ checked }) => {
      if (checked) {
        return `
          border-color: ${COLORS.BLUE_600};
          color: ${COLORS.BLUE_600};
        `;
      }
    }}
  }
`;

const ProfilePictureContainer = styled.div`
  margin-left: -10px;
  margin-right: 8px;
`;
