import COLORS from "./__generated__/base_colors";

type ExtendedColors = typeof COLORS & {
  BLUE_GRADIENT: string;
};

const extendedColors: ExtendedColors = {
  ...COLORS,
  BLUE_GRADIENT: "linear-gradient(91.99deg, #0068a7 0%, #009cea 100%)",
};

export default extendedColors;
